body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;

h1 {
  @apply text-2xl;
  @apply mt-2;
  @apply mb-1
}
h2 {
  @apply text-xl;
  @apply mt-2;
  @apply mb-1
}
h3 {
  @apply text-lg;
  @apply mt-2;
  @apply mb-1
}
p{
  @apply mb-3
}
li{
  @apply mb-2
}
@tailwind components;

@tailwind utilities;
